// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import Home from './components/Home';
import ItemPage from "./components/ItemPage";
import Schedule from "./components/schedule";
import Review from './components/Review';
import Booking from "./components/Booking"
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/MoveFlairModal.css';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => (
    <Router>
  <div>
    <Header />
      <Routes>
          <Route path="/" element={<Main/>} />
          <Route path="/home" element={<Home/>}/>
          <Route path="/items" element={<ItemPage/>}/>
          <Route path="/schedule" element={<Schedule/>}/>
          <Route path="/review" element={<Review/>}/>
          <Route path="/booking" element={<Booking/>}/>
      </Routes>
      <Footer/>
  </div>
    </Router>
);

export default App;
