// src/components/Main.js
import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CitiesModal from "./Modals/CitiesModal";
import Loader from './Animations/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { setFromAddress, setToAddress, setDistance } from '../features/moveSlice';
import '../styles/Main.css';
import '../styles/button.css';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

const Main = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const words = [
    { text: "Reimagined", colorClass: "color-0" },
    { text: "Just A Couch", colorClass: "color-1" },
    { text: "A Bed", colorClass: "color-2" },
    { text: "Your Furniture", colorClass: "color-3" },
    { text: "Anything", colorClass: "color-4" },

  ];
  const [fromInput, setFromInput] = useState('');
  const [toInput, setToInput] = useState('');
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [fromCoordinates, setFromCoordinates] = useState(null);
  const [toCoordinates, setToCoordinates] = useState(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchSuggestions = async (input, setSuggestions) => {
    if (input.length > 2) {
      const ontarioBox = [-95.153, 41.675, -74.343, 56.859]; // More specific bbox for Southern Ontario
      const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json`, {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
          autocomplete: true,
          types: 'address',
          country: 'CA',
          bbox: ontarioBox.join(','),
        },
      });
      setSuggestions(response.data.features.map(feature => ({
        name: feature.place_name,
        coordinates: feature.center,
      })));
    } else {
      setSuggestions([]);
    }
  };

  const fetchDistance = async (fromCoords, toCoords) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${fromCoords.join(',')};${toCoords.join(',')}`;
    try {
      const response = await axios.get(url, {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
          geometries: 'geojson',
        },
      });
      const distance = response.data.routes[0].distance / 1000; // Convert meters to kilometers
      dispatch(setDistance(distance)); // Dispatch distance to the store
    } catch (error) {
      console.error('Failed to fetch distance', error);
    }
  };

  const handleSearch = () => {
    if (fromCoordinates && toCoordinates) {
      fetchDistance(fromCoordinates, toCoordinates);
      setLoading(true);
      setTimeout(() => {
        navigate('/home');
      },2000)} //Show loader for two seconds

    else {
      console.log('Coordinates not set');
    }
  };
  const handleFromInputChange = (event) => {
    const input = event.target.value;
    setFromInput(input);
    fetchSuggestions(input, setFromSuggestions);
  };

  const handleToInputChange = (event) => {
    const input = event.target.value;
    setToInput(input);
    fetchSuggestions(input, setToSuggestions);
  };

  const handleSelectSuggestion = (suggestion, setInput, setSuggestions, setCoordinates, setAddressAction) => {
    setInput(suggestion.name);
    setCoordinates(suggestion.coordinates);
    setSuggestions([]);
    dispatch(setAddressAction(suggestion.name)); // Save the address to Redux store
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds
    return () => clearInterval(interval);
  }, [words.length]); // Add words.length as a dependency
  if (loading) {
    return <Loader />;
  }

  return (
    <main className="bg-col">
      <section className="intro">
        <div className="intro-text">
          <h1>
            Moving{" "}
            <span className={`flipping-word flipping-word-font ${words[wordIndex].colorClass}`}>
              {words[wordIndex].text}
            </span>
          </h1>
          <p>Experience the future of home transitions with MoveFlair. Get moving help and connect with local moving experts nearby.</p>
          <div className="form-container">
            <div className="input-icon">
              <input
                type="text"
                value={fromInput}
                onChange={handleFromInputChange}
                placeholder="Moving from..."
              />
              {fromSuggestions.length > 0 && (
                <ul className="autocomplete-results">
                  {fromSuggestions.map(suggestion => (
                    <li key={suggestion.name} onClick={() => handleSelectSuggestion(suggestion, setFromInput, setFromSuggestions, setFromCoordinates, setFromAddress)}>
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="input-icon">
              <input
                type="text"
                value={toInput}
                onChange={handleToInputChange}
                placeholder="Moving to..."
              />
              {toSuggestions.length > 0 && (
                <ul className="autocomplete-results">
                  {toSuggestions.map(suggestion => (
                    <li key={suggestion.name} onClick={() => handleSelectSuggestion(suggestion, setToInput, setToSuggestions, setToCoordinates, setToAddress)}>
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <br/>
            <button onClick={handleSearch} className="primary-button">Search</button>
            <small>Service is currently available in the Region of Waterloo, Guelph, and Stratford only. We’re planning to expand to<span style={{ color: '#dd7044', cursor: 'pointer' }} onClick={handleOpenModal}> more cities soon.</span></small>
          </div>
        </div>
        <div className="intro-graphic">
          <img src="/main_page.png" alt="Moving images" />
        </div>
      </section>
      <CitiesModal show={showModal} handleClose={handleCloseModal} />

    </main>
  );
};

export default Main;
