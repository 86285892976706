import React from 'react';
import '../styles/Footer.css'; // Ensure to create this CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p className="footer-phone">info@moveflair.com</p>
                <p className="footer-copyright">© {new Date().getFullYear()} MoveFlair. All rights reserved. <a href="https://www.moveflair.com">MoveFlair.com</a></p>
            </div>
        </footer>
    );
};

export default Footer;
