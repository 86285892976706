// Review.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../components/ProgressSteps/ProgressBar';
import PhoneVerification from "./Authentication/PhoneVerification";
import '../styles/Review.css';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
const Review = () => {
    const [loading, setLoading] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', phoneNumber: '' });
    const navigate = useNavigate();
    const { fromAddress, toAddress, distance, serviceDetails, items, schedule } = useSelector(state => state.move);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave? Your progress will be lost.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (!fromAddress || !toAddress) {
        return (
            <div className="p-5">
                <h2 className="text-xl font-bold text-red-500">Address details are missing. Please go back and enter your move details.</h2>
                <button
                    onClick={() => navigate('/')}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                >
                    Go Back
                </button>
            </div>
        );
    }

    if (!serviceDetails) {
        return (
            <div className="p-5">
                <h2 className="text-xl font-bold text-red-500">Service details are missing. Please go back and select a service.</h2>
                <button
                    onClick={() => navigate('/home')}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                >
                    Go Back
                </button>
            </div>
        );
    }

    const handleSubmit = async () => {
        if (!validateEmail(userDetails.email)) {
            alert('Please enter a valid email address.');
            return;
        }
        setLoading(true);

        const data = {
            "moving_from": fromAddress,
            "moving_to": toAddress,
            "distance": distance,
            "service_type": serviceDetails.serviceType,
            "number_of_movers": parseInt(serviceDetails.numMovers, 10),
            "mover_drives": serviceDetails.isMoveDrives,
            "schedule": schedule.toISOString(),
            "inventory": items.map(item => ({
                "item": item.furnitureName,
                "description": item.description,
                "quantity": parseInt(item.quantity, 10),
                "images": item.images.length
            })),
            "email": userDetails.email,
            "name": userDetails.name,
            "phone_number": userDetails.phoneNumber
        };

        try {
            const reviewResponse = await axios.post('https://atlas-go.onrender.com/api/v1/move', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const { user_id, booking_id } = reviewResponse.data;

            for (let item of items) {
                const formData = new FormData();

                item.images.forEach((image) => {
                    formData.append('image', image);
                });
                formData.append('user_id', user_id);
                formData.append('booking_id', booking_id);

                await axios.post('https://atlas-go.onrender.com/api/v1/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            navigate('/booking', { state: { booking_id } });
        } catch (error) {
            console.error("Error during submission:", error);
            alert("There was an error submitting your move. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex  = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handlePrevious = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/schedule');
        }, 2000);
    };

    const handleVerification = (verified, phoneNumber, name, email) => {
        setPhoneVerified(verified);
        setUserDetails({ phoneNumber, name, email });
    };

    return (
        <div className="p-5">
            <ProgressBar step="review" />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <PhoneVerification onVerify={handleVerification} />

                <div className="space-y-5 p-4 border border-gray-300 rounded-lg shadow-sm">
                    <h2 className="text-xl font-bold">Move Summary</h2>
                    <p><strong>Moving From:</strong> {fromAddress}</p>
                    <p><strong>Moving To:</strong> {toAddress}</p>
                    <p><strong>Distance:</strong> {distance} km</p>
                    <p><strong>Service Type:</strong> {serviceDetails.serviceType}</p>
                    <p><strong>Number of Movers:</strong> {serviceDetails.numMovers}</p>
                    <p><strong>Schedule:</strong> {new Date(schedule).toLocaleString()}</p>
                    <hr/>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <div key={index}>
                                <h3 className="text-xl font-bold">Furniture Inventory</h3>
                                <p><strong>Furniture Name:</strong> {item.furnitureName}</p>
                                <p><strong>Description:</strong> {item.description}</p>
                                <p><strong>Quantity:</strong> {item.quantity}</p>
                                <p><strong>Images Uploaded:</strong> {item.images.length}</p>
                            </div>
                        ))
                    ) : (
                        <p>No items have been added yet.</p>
                    )}
                </div>
            </div>

            <div className="flex justify-between mt-5">
                <button
                    onClick={handlePrevious}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
                >
                    Previous
                </button>
                <button
                    onClick={handleSubmit}
                    className={`py-2 px-4 rounded transition-colors duration-300 ${phoneVerified && userDetails.name && userDetails.email ? 'bg-orange-500 hover:bg-orange-700 text-white' : 'bg-gray-400 text-gray-200 cursor-not-allowed'}`}
                    disabled={!phoneVerified || !userDetails.name || !userDetails.email || loading}
                >
                    Submit
                </button>
            </div>

            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Loading...</p>
                </div>
            )}
        </div>
    );
};

export default Review;
