import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setServiceDetails } from '../../features/moveSlice';
import ProgressBar from '../../components/ProgressSteps/ProgressBar';

const templates = [
    {
        id: 1,
        name: 'A Mover who drives',
        imageUrl: '/moveranddriver.jpg',
        description: 'Get expert assistance with loading, unloading, and driving. Including dismantling and reassembly when needed.',
        serviceType: 'movers',
        moverDrives: true
    },
    {
        id: 2,
        name: 'Just a Mover',
        imageUrl: '/moveronly.jpg',
        description: 'Need help with loading and unloading, including dismantling and reassembly? This service does not include a driver.',
        serviceType: 'movers',
        moverDrives: false
    },
    {
        id: 3,
        name: 'Driver Only',
        imageUrl: '/driver.jpg',
        description: 'Need someone to just drive your items',
        serviceType: 'drivers',
        moverDrives: true
    },
];

const SelectService = () => {
    const [loading, setLoading] = useState(false);
    const [selectedMovers, setSelectedMovers] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleServiceSelection = (template) => {
        if (selectedMovers[template.id]) {
            dispatch(setServiceDetails({
                serviceType: template.serviceType,
                numMovers: selectedMovers[template.id],
                isMoveDrives: template.moverDrives
            }));
            setLoading(true);
            setTimeout(() => {
                navigate('/items');
            }, 2000);
        } else {
            alert("Please select the number of movers.");
        }
    };

    const handleMoversChange = (id, value) => {
        setSelectedMovers({ ...selectedMovers, [id]: value });
    };

    const getMoverOptions = (serviceType, moverDrives) => {
        if (serviceType === 'drivers') {
            return (
                <>
                    <option value="1">1 Driver: (Driver only service.)</option>
                </>
            );
        } else if (serviceType === 'movers' && moverDrives) {
            return (
                <>
                    <option value="1">1 Mover: (Need assistance with your move? The mover will help and drive too.)</option>
                    <option value="2">2 Movers: (For heavy lifting—one mover will handle the driving.)</option>
                </>
            );
        } else if (serviceType === 'movers' && !moverDrives) {
            return (
                <>
                    <option value="1">1 Mover: (Assistance with loading and unloading only, no driving.)</option>
                    <option value="2">2 Movers: (For heavy lifting, both movers will assist with loading and unloading.)</option>
                </>
            );
        }
    };

    return (
        <div className="p-5">
            <ProgressBar step="service" />
            <h1 className="text-3xl font-bold mb-5">Select A Service</h1>
            <div className="grid gap-5 grid-cols-1 md:grid-cols-3">
                {templates.map((template) => (
                    <div key={template.id} className="bg-white rounded-lg shadow-lg p-5 hover:shadow-xl transition-shadow duration-300">
                        <div className="mb-4">
                            <img src={template.imageUrl} alt={template.name} className="w-full h-32 object-cover rounded-t-lg" />
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold">{template.name}</h3>
                            <div className="mt-3">
                                <label htmlFor={`movers-${template.id}`} className="block text-gray-700">Choose an option:</label>
                                <select
                                    id={`movers-${template.id}`}
                                    className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                                    value={selectedMovers[template.id] || ''}
                                    onChange={(e) => handleMoversChange(template.id, e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    {getMoverOptions(template.serviceType, template.moverDrives)}
                                </select>
                            </div>
                            <button
                                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                                onClick={() => handleServiceSelection(template)}
                            >
                                Select
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Preparing your service...</p>
                </div>
            )}
        </div>
    );
};

export default SelectService;