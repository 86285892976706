import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fromAddress: '',
  toAddress: '',
  distance: 0,
  serviceDetails: null,
  items: [],
  schedule: null,
};

const moveSlice = createSlice({
  name: 'move',
  initialState,
  reducers: {
    setFromAddress(state, action) {
      state.fromAddress = action.payload;
    },
    setToAddress(state, action) {
      state.toAddress = action.payload;
    },
    setDistance(state, action) {
      state.distance = action.payload;
    },
    setServiceDetails(state, action) {
      state.serviceDetails = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    setSchedule(state, action) {
      state.schedule = action.payload;
    },
  },
});

export const { setFromAddress, setToAddress, setDistance, setServiceDetails, setItems, setSchedule } = moveSlice.actions;
export default moveSlice.reducer;