import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from '../components/ProgressSteps/ProgressBar';
import { useDispatch } from 'react-redux';
import { setSchedule } from '../features/moveSlice';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
const Schedule = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleDateChange = (date) => {
        setStartDate(date);
    };

    const dispatch = useDispatch();

    const handleNext = () => {
        if (startDate) {
            dispatch(setSchedule(startDate)); // Save schedule to Redux store
            setLoading(true);
            setTimeout(() => {
                navigate('/review');
            }, 2000);
        } else {
            alert('Please select a date and time.');
        }
    };

    const handlePrevious = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/items');
        }, 2000);
    };

    return (
        <div className="p-5">
            <ProgressBar step="schedule" />
            <h1 className="text-3xl font-bold mb-5 text-center">Schedule Your Move</h1>
            <div className="flex justify-center items-center">
                <div className="space-y-5 w-full max-w-md">
                    <div>
                        <label className="block text-gray-700 text-center">Select Date and Time</label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            showTimeSelect
                            dateFormat="Pp"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex justify-between mt-5">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Loading...</p>
                </div>
            )}
        </div>
    );
};

export default Schedule;