import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../components/ProgressSteps/ProgressBar';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setItems as setReduxItems } from '../features/moveSlice';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
const ItemPage = () => {
    const dispatch = useDispatch();

    const [items, setLocalItems] = useState([
        { furnitureName: '', description: '', quantity: '', images: [] },
    ]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setLocalItems(newItems);
    };

    const handleFileChange = (index, e) => {
        const files = Array.from(e.target.files);
        const newItems = [...items];
        newItems[index].images = [...newItems[index].images, ...files];
        setLocalItems(newItems);
    };

    const addItemField = () => {
        setLocalItems([
            ...items,
            { furnitureName: '', description: '', quantity: '', images: [] },
        ]);
    };

    const deleteItemField = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setLocalItems(newItems);
    };

    const handleNext = () => {
        if (items.every(item => item.furnitureName && item.description && item.quantity && item.images.length > 0)) {
            dispatch(setReduxItems(items)); // Save items to Redux store
            setLoading(true);
            setTimeout(() => {
                navigate('/schedule');
            }, 2000);
        } else {
            alert('Please fill all fields and upload images for each item before proceeding.');
        }
    };

    const handlePrevious = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/home');
        }, 2000);
    };

    return (
        <div className="p-5">
            <ProgressBar step="items" />
            <h1 className="text-3xl font-bold mb-5">Add Items</h1>
            <form className="space-y-5">
                {items.map((item, index) => (
                    <div key={index} className="space-y-4 border p-4 rounded-lg shadow-md mb-4">
                        <div className="flex justify-between">
                            <h2 className="text-lg font-semibold">Item {index + 1}</h2>
                            <button
                                type="button"
                                className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                onClick={() => deleteItemField(index)}
                            >
                                <FaTrash /> Delete
                            </button>
                        </div>
                        <div>
                            <label className="block text-gray-700">Furniture Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                value={item.furnitureName}
                                onChange={(e) => handleInputChange(index, 'furnitureName', e.target.value)}
                                placeholder="Enter furniture name"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Item Description</label>
                            <textarea
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                value={item.description}
                                onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                placeholder="Enter item description"
                                rows="4"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Quantity</label>
                            <input
                                type="number"
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                value={item.quantity}
                                onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                placeholder="Enter quantity"
                                min="1"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Upload Images</label>
                            <input
                                type="file"
                                accept=".heic, .jpg, .jpeg, .png, .heif, .webp"
                                className="mt-1 block w-full text-gray-700"
                                onChange={(e) => handleFileChange(index, e)}
                                multiple
                            />
                            <div className="text-sm text-gray-600 mt-2">
                                {item.images.length} image{item.images.length !== 1 && 's'} added
                            </div>
                        </div>
                    </div>
                ))}
                <button
                    type="button"
                    className="flex items-center bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors duration-300"
                    onClick={addItemField}
                >
                    <FaPlus className="mr-2" /> Add Another Item
                </button>
                <div className="flex justify-between mt-5">
                    <button
                        type="button"
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
                        onClick={handlePrevious}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                        onClick={handleNext}
                    >
                        Next
                    </button>
                </div>
            </form>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Loading...</p>
                </div>
            )}
        </div>
    );
};

export default ItemPage;